'use client';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { AWS_COGNITO_RESPONSE_TYPE, AWS_COGNITO_SCOPE } from '@/lib/auth';
import { getRedirectSearchParam } from '@/lib/redirect-search-param';
import { IdentityProvider } from '@/models/settings';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { SiGithub } from 'react-icons/si';
import { VscAzureDevops } from 'react-icons/vsc';
import { useRootStore } from '../_providers/root-store-provider';

const generateLoginLink = (
  authUrl: string,
  clientId: string,
  identity_provider_name: string,
  redirectPath: string | null,
) => {
  const params = new URLSearchParams({
    client_id: clientId,
    identity_provider: identity_provider_name,
    response_type: AWS_COGNITO_RESPONSE_TYPE,
    scope: AWS_COGNITO_SCOPE,
    redirect_uri: `${window.location.origin}/callback`,
    state: redirectPath || '/',
  });

  return `${authUrl}/oauth2/authorize?${params.toString()}`;
};

const getIcon = (providerId: string) => {
  switch (providerId) {
    case IdentityProvider.AZURE: {
      return <VscAzureDevops className="mr-3 h-6 w-6" />;
    }
    case IdentityProvider.GITHUB: {
      return <SiGithub className="mr-3 h-6 w-6" />;
    }

    default: {
      return null;
    }
  }
};

export default observer(() => {
  const router = useRouter();
  const rootStore = useRootStore();

  useEffect(() => {
    const redirectIfAlreadyLoggedIn = async () => {
      if (rootStore.authStore.isLoggedIn === true) {
        const redirectPath = getRedirectSearchParam() || '/dashboard/overview';
        router.replace(redirectPath);
      }
    };
    redirectIfAlreadyLoggedIn();
  }, [rootStore.authStore.isLoggedIn, router]);

  return (
    <div className="mx-3 flex justify-center">
      <Card className="flex min-h-48 w-4/5 flex-col items-center border-0 bg-backdrop shadow-none">
        <CardHeader>
          <CardTitle className="center text-4xl">Log in to Nullify</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid gap-3">
            {rootStore.settingsStore.settings.rawData.identityProviders.map(
              (provider) => (
                <Link
                  href={
                    rootStore.settingsStore.settings.rawData
                      ? generateLoginLink(
                          rootStore.settingsStore.settings.rawData.authUrl,
                          rootStore.settingsStore.settings.rawData.clientId,
                          provider.identityProviderName,
                          getRedirectSearchParam(),
                        )
                      : ''
                  }
                  key={provider.id}
                >
                  <Button
                    key={provider.id}
                    variant="outline"
                    className="w-full bg-slate-600 py-6 text-lg text-white"
                  >
                    {getIcon(provider.id)}
                    Continue with {provider.identityProviderName}
                  </Button>
                </Link>
              ),
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
});
